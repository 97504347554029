import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { OrganizationLevelDTO } from '../dto/organization-level.dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationLevelService {
  private apiUrl = `${environment.apiUrl}/organization-levels`;

  constructor(private http: HttpClient) { }

  getOrganizationLevels(): Observable<OrganizationLevelDTO[]> {
    return this.http.get<OrganizationLevelDTO[]>(this.apiUrl);
  }

  updateOrganizationLevel(
    level: OrganizationLevelDTO[]): Observable<OrganizationLevelDTO[]> {
    return this.http.post<OrganizationLevelDTO[]>(`${this.apiUrl}`, level);
  }
}
